/*
 * Copyright 2019 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except
 * in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'sign-in-with-email',
  template: `
    <div class="card">
      <div class="card-header sign-in-header">
       Sign in
      </div>
      <div class="card-body">
        <h5 class="card-title">
          {{ showEnterPassword ? 'Sign in' : 'Reset password'}}
        </h5>
        <form id="sign-in-form">
          <div *ngIf="showEnterPassword; else resetPassword">
            <div class="form-group">
              <p class="card-text">Enter password for <b>{{email}}</b></p>
              <label for="password">Password</label>
              <input #password class="form-control"
                  type="password"
                  placeholder="Password"
                  name="password"
                  id="password">
            </div>
            <div>
              <div class="d-flex flex-row align-items-center">
                <a href="{{ signInUrl }}" class="btn btn-link mr-auto">&#8592; Back</a>
                <button [disabled]="!password.value" type="submit" class="btn btn-primary password-sign-in"
                        (click)="onSignInWithEmailAndPassword(password.value)">
                      Sign in
                </button>
                <a href="#" class="ml-2" (click)="showEnterPassword = false; $event.preventDefault(); $event.stopPropagation()">or reset password</a>
              </div>
            </div>
          </div>
          <ng-template #resetPassword>
            <div class="form-group">
              <label for="email">Email</label>
              <input #emailInput class="form-control"
                  type="text"
                  placeholder="Email"
                  name="email"
                  id="email">
            </div>
            <div>
              <a href="#" (click)="showEnterPassword = true; $event.preventDefault(); $event.stopPropagation()" class="btn btn-link">&#8592; Back</a>
              <button [disabled]="!emailInput.value" type="submit" class="btn btn-primary mb-2 password-sign-in"
                (click)="onSendPasswordResetEmail(emailInput.value)">
                Reset
              </button>
            </div>
          </ng-template>
        </form>
      </div>
    </div>
    `,
})
export class SignInWithEmailComponent {
  @Input() public email: string;
  @Input() public onSignInWithEmailAndPassword: (password: string) => boolean;
  @Input() public onSendPasswordResetEmail: (email: string) => boolean;

  showEnterPassword = true;

  get signInUrl() {
    return window.location.href;
  }
}
