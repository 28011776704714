import { Component } from '@angular/core';

@Component({
  selector: 'send-password-reset-email',
  template: `
    <div class="alert alert-success" role="alert">
      A recovery link was sent, if the provided email belongs to an account.
    </div>
  `,
})
export class SendPasswordResetEmailComponent {}
